import React from 'react'
import { Link, navigate } from 'gatsby'
import { sessionKeys, errorMessageTemplates } from "../services/const"
import { getFormValues, setFormValues } from '../services/form'
import { getValue } from '../utils/check'
import Seo from '../components/seo'

const displayLabels = {
  LastNameKanji: { Label: "お名前", Property: "NameKanji" },
  FirstNameKanji: { Label: "お名前", Property: "NameKanji" },
  LastNameKatakana: { Label: "お名前（フリガナ）", Property: "NameKatakana" },
  FirstNameKatakana: { Label: "お名前（フリガナ）", Property: "NameKatakana" },
  BirthYear: { Label: "生年月日", Property: "BirthDate" },
  BirthMonth: { Label: "生年月日", Property: "BirthDate" },
  BirthDay: { Label: "生年月日", Property: "BirthDate" },
  TelephoneNumber: { Label: "電話番号" },
  MailAddress: { Label: "メールアドレス" }
}

class RemindNumber extends React.Component {
  state = {
    LastNameKanji: "",
    FirstNameKanji: "",
    LastNameKatakana: "",
    FirstNameKatakana: "",
    BirthYear: "",
    BirthMonth: "",
    BirthDay: "",
    TelephoneNumber: "",
    MailAddress: "",
    ErrorLabelTexts: {}
  }

  getErrorLabelTexts = (state) => {
    const errorLabelTexts = {}
    const requires = ["LastNameKanji", "FirstNameKanji", "LastNameKatakana", "FirstNameKatakana",
      "BirthYear", "BirthMonth", "BirthDay", "TelephoneNumber", "MailAddress"]
    requires.forEach(v => {
      if (state[v] === null ||
        state[v] === "" ||
        state[v] === void 0 ||
        state[v].match(/^[ 　\r\n\t]*$/)) {
        errorLabelTexts[getValue(displayLabels[v].Property, v)] = displayLabels[v].Label + errorMessageTemplates.noInput
      } else if (v === "TelephoneNumber") {
        var tel = state[v].replace(/[━.*‐.*―.*－.*\-.*ー]/gi, '');
        if (!tel.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)) {
          errorLabelTexts[getValue(displayLabels[v].Property, v)] = displayLabels[v].Label + errorMessageTemplates.typo
        }
      } else if (v === "MailAddress") {
        if (!state[v].match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/)) {
          errorLabelTexts[getValue(displayLabels[v].Property, v)] = displayLabels[v].Label + errorMessageTemplates.typo
        }
      }
    })
    return errorLabelTexts
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleOnBlur = event => {
  }

  handleSubmit = event => {
    event.preventDefault()

    const errorMessages = this.getErrorLabelTexts(this.state)
    if (Object.keys(errorMessages).length > 0) {
      this.setState({
        ErrorLabelTexts: errorMessages
      })
      navigate('#')
    }
    else {
      setFormValues(sessionKeys.remindForm, this.state)
      navigate('/remindNumberConfirm')
    }
  }

  componentDidMount = () => {
    const restoreValue = getFormValues(sessionKeys.remindForm, "ErrorLabelTexts")
    Object.keys(restoreValue).forEach(k => {
      this.setState({
        [k]: restoreValue[k],
      })
    })
  }

  render = () => {
    return (
      <>
        <Seo title="会員番号のお問合せ" />
        <div id="pageTitle">
          <h1>
            会員番号のお問合せ
          </h1>
        </div>
        <div className="breadClumb">
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li>会員番号のお問合せ</li>
          </ul>
        </div>
        <div id="main">
          <div className="contents">
            <section className="contents_block">
              <p className="mb40">会員番号が不明の方は、下記のフォームに入力しお問い合わせいただくか、03-5846-5300又は054-3489へ連絡をしてください。<br />
                （*は入力必須項目です。）</p>
              <p className="remind_notice">パスワードがわからない方は協会誌の当月号をご覧ください。</p>
            </section>
            <section className="contents_block narrow mb50">
              <form name="" onSubmit={this.handleSubmit}>
                <div className="form_block">
                  <div className="form_head">
                    <span className="form_require">お名前</span>
                  </div>
                  <div className="form_body">
                    <div className="form_body_parent">
                      <div className="form_body_child">
                        姓<input type="text" className="" name="LastNameKanji" value={this.state.LastNameKanji} onChange={this.handleInputChange} />
                      </div>
                      <div className="form_body_child">
                        名<input type="text" className="" name="FirstNameKanji" value={this.state.FirstNameKanji} onChange={this.handleInputChange} />
                      </div>
                    </div>
                    <div className="err"> {this.state.ErrorLabelTexts.NameKanji} </div>
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    <span className="form_require">お名前（カナ）</span>
                  </div>
                  <div className="form_body">
                    <div className="form_body_parent">
                      <div className="form_body_child">
                        姓（カナ）<input type="text" className="" name="LastNameKatakana" value={this.state.LastNameKatakana} onChange={this.handleInputChange} />
                      </div>
                      <div className="form_body_child">
                        名（カナ）<input type="text" className="" name="FirstNameKatakana" value={this.state.FirstNameKatakana} onChange={this.handleInputChange} />
                      </div>
                    </div>
                    <div className="err"> {this.state.ErrorLabelTexts.NameKatakana} </div>
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    <span className="form_require">生年月日</span>
                  </div>
                  <div className="formField">
                    <div className="form_body three_selecter">
                      <label className="select-group1">
                        <select name="BirthYear" className="select" value={this.state.BirthYear} onChange={this.handleInputChange} onBlur={this.handleOnBlur}>
                          <option value="">選択</option>
                          <option value="1900">1900</option>
                          <option value="1901">1901</option>
                          <option value="1902">1902</option>
                          <option value="1903">1903</option>
                          <option value="1904">1904</option>
                          <option value="1905">1905</option>
                          <option value="1906">1906</option>
                          <option value="1907">1907</option>
                          <option value="1908">1908</option>
                          <option value="1909">1909</option>
                          <option value="1910">1910</option>
                          <option value="1911">1911</option>
                          <option value="1912">1912</option>
                          <option value="1913">1913</option>
                          <option value="1914">1914</option>
                          <option value="1915">1915</option>
                          <option value="1916">1916</option>
                          <option value="1917">1917</option>
                          <option value="1918">1918</option>
                          <option value="1919">1919</option>
                          <option value="1920">1920</option>
                          <option value="1921">1921</option>
                          <option value="1922">1922</option>
                          <option value="1923">1923</option>
                          <option value="1924">1924</option>
                          <option value="1925">1925</option>
                          <option value="1926">1926</option>
                          <option value="1927">1927</option>
                          <option value="1928">1928</option>
                          <option value="1929">1929</option>
                          <option value="1930">1930</option>
                          <option value="1931">1931</option>
                          <option value="1932">1932</option>
                          <option value="1933">1933</option>
                          <option value="1934">1934</option>
                          <option value="1935">1935</option>
                          <option value="1936">1936</option>
                          <option value="1937">1937</option>
                          <option value="1938">1938</option>
                          <option value="1939">1939</option>
                          <option value="1940">1940</option>
                          <option value="1941">1941</option>
                          <option value="1942">1942</option>
                          <option value="1943">1943</option>
                          <option value="1944">1944</option>
                          <option value="1945">1945</option>
                          <option value="1946">1946</option>
                          <option value="1947">1947</option>
                          <option value="1948">1948</option>
                          <option value="1949">1949</option>
                          <option value="1950">1950</option>
                          <option value="1951">1951</option>
                          <option value="1952">1952</option>
                          <option value="1953">1953</option>
                          <option value="1954">1954</option>
                          <option value="1955">1955</option>
                          <option value="1956">1956</option>
                          <option value="1957">1957</option>
                          <option value="1958">1958</option>
                          <option value="1959">1959</option>
                          <option value="1960">1960</option>
                          <option value="1961">1961</option>
                          <option value="1962">1962</option>
                          <option value="1963">1963</option>
                          <option value="1964">1964</option>
                          <option value="1965">1965</option>
                          <option value="1966">1966</option>
                          <option value="1967">1967</option>
                          <option value="1968">1968</option>
                          <option value="1969">1969</option>
                          <option value="1970">1970</option>
                          <option value="1971">1971</option>
                          <option value="1972">1972</option>
                          <option value="1973">1973</option>
                          <option value="1974">1974</option>
                          <option value="1975">1975</option>
                          <option value="1976">1976</option>
                          <option value="1977">1977</option>
                          <option value="1978">1978</option>
                          <option value="1979">1979</option>
                          <option value="1980">1980</option>
                          <option value="1981">1981</option>
                          <option value="1982">1982</option>
                          <option value="1983">1983</option>
                          <option value="1984">1984</option>
                          <option value="1985">1985</option>
                          <option value="1986">1986</option>
                          <option value="1987">1987</option>
                          <option value="1988">1988</option>
                          <option value="1989">1989</option>
                          <option value="1990">1990</option>
                          <option value="1991">1991</option>
                          <option value="1992">1992</option>
                          <option value="1993">1993</option>
                          <option value="1994">1994</option>
                          <option value="1995">1995</option>
                          <option value="1996">1996</option>
                          <option value="1997">1997</option>
                          <option value="1998">1998</option>
                          <option value="1999">1999</option>
                          <option value="2000">2000</option>
                          <option value="2001">2001</option>
                          <option value="2002">2002</option>
                          <option value="2003">2003</option>
                          <option value="2004">2004</option>
                          <option value="2005">2005</option>
                          <option value="2006">2006</option>
                          <option value="2007">2007</option>
                          <option value="2008">2008</option>
                          <option value="2009">2009</option>
                          <option value="2010">2010</option>
                          <option value="2011">2011</option>
                          <option value="2012">2012</option>
                          <option value="2013">2013</option>
                          <option value="2014">2014</option>
                          <option value="2015">2015</option>
                          <option value="2016">2016</option>
                          <option value="2017">2017</option>
                          <option value="2018">2018</option>
                          <option value="2019">2019</option>
                          <option value="2020">2020</option>
                          <option value="2021">2021</option></select>
                      </label>
                      年&nbsp;
                      <label className="select-group1">
                        <select name="BirthMonth" className="select" value={this.state.BirthMonth} onChange={this.handleInputChange} onBlur={this.handleOnBlur}>
                          <option value="">選択</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                      </label>
                      月&nbsp;
                      <label className="select-group1">
                        <select name="BirthDay" className="select" value={this.state.BirthDay} onChange={this.handleInputChange} onBlur={this.handleOnBlur}>
                          <option value="">選択</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                          <option value="31">31</option>
                        </select>
                      </label>
                      日</div>
                    <div className="err"> {this.state.ErrorLabelTexts.BirthDate} </div>
                  </div>
                </div>
                <div className="form_block">
                  <div className="form_head">
                    <span className="form_require">お電話番号</span>
                  </div>
                  <div className="form_body">
                    <input type="text" className="" placeholder="例）03-1234-5678" name="TelephoneNumber" value={this.state.TelephoneNumber} onChange={this.handleInputChange} />
                    <div className="err"> {this.state.ErrorLabelTexts.TelephoneNumber} </div>
                  </div>
                </div>
                <div className="form_block">
                  <div className="form_head">
                    <span className="form_require">メールアドレス</span>
                  </div>
                  <div className="form_body">
                    <input type="text" className="" name="MailAddress" value={this.state.MailAddress} onChange={this.handleInputChange} />
                    <div className="err"> {this.state.ErrorLabelTexts.MailAddress} </div>
                  </div>
                </div>
                <div className="aC"><input type="submit" value="入力内容を確認する" className="btn_rounded" /></div>
              </form>
            </section>
          </div>
        </div>
      </>
    )
  }
}

export default RemindNumber